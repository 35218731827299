<template>
  <div>
    <div class="text-h5 mb-3">{{ $t('labels.select_employee') }}</div>
    <v-row>
      <v-col cols="12" sm="6" md="3" class="font-weight-bold">
        <v-checkbox
            dense
            hide-details
            v-model="allSelected"
            :label="$t('labels.all')"
            @change="allToggle"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="6" md="3" v-for="employee in employees" :key="`emp_${employee.id}`">
        <v-checkbox
            dense
            hide-details
            v-model="selected"
            :label="employee.full_name"
            :value="employee.id"
            multiple
            @change="itemToggle(employee.id)"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div class="mt-5">
      <v-btn color="info" small @click="printStampTest()">In tem test</v-btn>
    </div>
  </div>
</template>

<script>
import {httpClient} from "@/libs/http";
export default {
  name: "PrintStamp",
  data: () => ({
    employees: [],
    selected: [],
    allSelected: false
  }),
  mounted() {
    this.getEmployees()
    this.listenSocket()
  },
  methods: {
    listenSocket () {
      if (!this.isMobileAgent() && this.checkPermission(['pos_download_goods_stamp'])) {
        const myPoss = [...window.me.poss].map(p => (p.id))
        window.socket.on('goods_url_download_stamp', data => {
          console.log('data', data)
          const { url, id_pos, id_employee } = data
          if (myPoss.includes(id_pos) && this.selected.includes(id_employee)) {
            const params = data.params && JSON.parse(data.params) || {}
            this.downloadExcelFile(url, params, params.file_name)
          }
        })
      }
    },
    async getEmployees() {
      const {data} = await httpClient.post('/employee-list')
      this.employees = [...data.rows]
    },
    allToggle() {
      if (!this.allSelected) {
        this.selected = []
      } else {
        this.selected = this.employees.map(o => (o.id))
      }
    },
    itemToggle(val) {
      if (!this.selected.includes(val)) {
        this.allSelected = false
      }
    },

    async printStampTest() {
      await this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/generate-material-stamp`, {
        id_goods: 688915,
        insert_tracking: 'P7VXAGBP',
      }, `50x20_2000202717575_P7VXAGBP.pdf`)
    },
  }
}
</script>

<style scoped>

</style>
